import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";

import { useScreen } from "hooks/useScreen";
import { useStorage } from "hooks/useStorage";

import LogoLockerimRavBariah from "assets/images/logo_lockerim_rav_bariah.png";
import useTranslations from "hooks/useTranslations";

import { ReactComponent as PhoneLogo } from "assets/images/phone.svg";

import "./Footer.scss";
import CONSTANTS from "utils/constants";

const Footer = () => {
  const { isDesktop } = useScreen();
  const { rcTranslate } = useTranslations();

  const history = useHistory();
  const { removeLocalStorageData } = useStorage();
  const [storeHref, setStoreHref] = useState(null);

  const topDetailsLinks = [
    {
      title: "ראשי",
      link: "/"
    },
    {
      title: "איך זה עובד",
      link: "/how-it-works"
    },
    {
      title: "שאלות ותשובות",
      link: "/faq"
    }
  ];

  const bottomDetailsLinks = [
    {key: "cancelOrderRequest", className: "footer__linkBold", target: "_blank"},
    {key: "cancelingPolicy", className: "footer__linkBold", target: "_blank"},
    {key: "termsOfUse", className: "footer__link"},
    {key: "privacyPolicy", className: "footer__link"},
    {key: "cookiesPolicy", className: "footer__link"},
  ].map(({key, className, target}) => (
    {
      title: rcTranslate(`footer.bottomDetailsLinks.${key}.label`),
      link: rcTranslate(`footer.bottomDetailsLinks.${key}.link`),
      className,
      target,
    }
  ))

  const getOsType = () => {
    const userAgent = window.navigator.userAgent;
  
    if (/Android/i.test(userAgent)) {
      return 'Android';
    }
  
    if (/iPad|iPhone|iPod|Mac/.test(userAgent)) {
      return 'iOS';
    }
  
    if (/Windows.+Chrome/i.test(userAgent)) {
      return 'Android';
    }
  
    if (navigator.platform.toUpperCase().includes('MAC')) {
      return 'Mac';
    }
  
    return 'Unknown';
  };
  

  useEffect(() => {
    if (getOsType() === 'Unknown') return;
    else if (getOsType() === 'Android') setStoreHref(CONSTANTS.LOCKERIM_MOBILE_CLIENT_APP_STORE_LINKS.GOOGLE_PLAY);
    else if (getOsType() === 'iOS') setStoreHref(CONSTANTS.LOCKERIM_MOBILE_CLIENT_APP_STORE_LINKS.APP_STORE);
  }, []);

  const goToMainPage = () => {
    removeLocalStorageData();
    history.push("/");
  };

  return ReactDOM.createPortal(
    <div className="footer__wrapper">
      <div className="footer__topDetails">
        <div className="footer__logoWrapper">
          <button className="footer__logo-button" onClick={goToMainPage}>
            <img src={LogoLockerimRavBariah} alt="logoRavBariah" className="logoImage" onClick={goToMainPage}/>
          </button>
        </div>

        <div className="footer__topDetails-links">
          {topDetailsLinks.map((item, index) => (
            <Link key={index} className="footer__link" to={item.link}>
              {item.title}
            </Link>
          ))}
        </div>

        <div className="footer__topDetails-customerService">
          <PhoneLogo className="footer__topDetails-phoneLogo" />

          <div className="footer__topDetails-customerServiceDetails">
            <div>{rcTranslate("footer.supportTitle")}</div>
            <div>{rcTranslate("footer.supportPhoneNumber")}</div>
          </div>
        </div>
      </div>

      <div className={isDesktop ? "footer__bottomDetails" : "footer__bottomDetails-mobile"}>
        <div className="footer__bottomDetails-links">
          {bottomDetailsLinks.map((item, index) => (
            <a key={index} className={item.className} target={item.target} href={item.link}>
              {item.title}
            </a>
          ))}
        </div>

        <div className="footer__bottomDetails-copyright">
          {`כל הזכויות שמורות ללוקר אמבין 2022 © \nבת שבע 1, לוד ת.ד: 549, מיקוד: 711600`}
        </div>
      </div>
    </div>,
    document.getElementById("footer")
  );
};

export default Footer;
